class action{
  constructor(
    label           = 'string', 
    iconClassName   = 'mdi mdi-arrow-right', 
    onClick         = null, 
    show            = true,
    position        = 'left',
    iconOnly        = false
  ){
    this.label = label
    this.iconClassName = iconClassName
    this.onClick = onClick
    this.show = show
    this.position = position
    this.iconOnly = iconOnly
  }
}

export default action