import { makeAutoObservable } from 'mobx'; 
import cookies from 'react-cookies';

class AuthStore {
  user = {};
  isLoggedIn = false;
  logoutAction = null;
  tokenNames = {
    accessToken: 'MPK_ACCESS_TOKEN',
    refreshToken: 'MPK_REFRESH_TOKEN',
    guestToken: 'MPK_GUEST_TOKEN',
    accessKey: 'MPK_ACCESS_KEY'
  }
  hasAccessToken = cookies.load(this.tokenNames.accessToken);

  constructor(){
    makeAutoObservable(this)
  }

  setTokenNames(accessToken, refreshToken, guestToken, accessKey){
    this.tokenNames.accessToken = accessToken;
    this.tokenNames.refreshToken = refreshToken;
    this.tokenNames.guestToken = guestToken;
    this.tokenNames.accessKey = accessKey;
    this.hasAccessToken = cookies.load(this.tokenNames.accessToken);
  }

  setHasAccessToken(hasAccessToken){
    this.hasAccessToken = hasAccessToken;
  }

  setProfile(user){
    this.user = user;
    this.isLoggedIn = true;
  }

  setLogoutAction(logoutAction){
    this.logoutAction = logoutAction
  }

  clearCookie = (opt={path:'/'}) => {
    cookies.remove(this.tokenNames.accessToken, opt);
    cookies.remove(this.tokenNames.refreshToken, opt);
    cookies.remove(this.tokenNames.accessKey, opt);
  }

  logout = (redirectUri, autoBack=false) => {
    redirectUri = redirectUri || window.location.origin
    if(this.logoutAction) this.logoutAction(window.encodeURIComponent(redirectUri), autoBack ? 1 : 0 , () => {
      this.user = {};
      this.isLoggedIn = false;
      this.clearCookie();
    }); 
    else {
      this.user = {};
      this.isLoggedIn = false;
      this.clearCookie();
    }
  }

  setAccessToken = (accessToken, config={path:'/'}) => {
    cookies.save(this.tokenNames.accessToken, accessToken, config);
  }

  getAccessToken = () => {
    let accessToken = cookies.load(this.tokenNames.accessToken);
    return accessToken;
  }

  getGuestToken = () => {
    let guestToken = cookies.load(this.tokenNames.guestToken);
    return guestToken;
  }

  setAccessKey = (accessKey, config={path:'/'}) => {
    console.log(accessKey)
    cookies.save(this.tokenNames.accessKey, accessKey, config);
  }

  getAccessKey = () => {
    let accessKey = cookies.load(this.tokenNames.accessKey);
    return accessKey;
  }

  refreshingToken = () => {
    return new Promise(resolve => {
      window.dispatchEvent(new CustomEvent('sso-custom-message', {detail: {
        eventName: 'check-token',
        callback: accessToken => resolve(accessToken)
      }}))
    })
    
  }

  hasPermission = (resourceUri, resources=this.user.resources) => {
    if(resourceUri === '') return true;
    else{
      const uris = resourceUri.split(',');
      let allowed = false
      for(let uri of uris){
        allowed = resources.indexOf(uri) >= 0;
        if(allowed) break;
      }
      return allowed;
    }
  }
}

export default AuthStore;