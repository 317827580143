import React, { useState, useEffect} from 'react'
import Picture from './Picture'
import { DropdownMenu, FontIcon } from 'react-md'
import t from 'counterpart'
import { inject, observer } from 'mobx-react';
import { Flex } from '..';

const AppInfo = (props) => {
  return(
    <div>
      <div className="mpk-font size-L weight-B">{props.envStore.env.appInfo.name}</div>
      <hr />
      <div className="mpk-font size-N weight-B">BE Version</div>
      <div className="mpk-font size-NS">{window.emeterai_version} {window.emeterai_version_detail}</div>
      <br ></br>
      <div className="mpk-font size-N weight-B">FE Version</div>
      <div className="mpk-font size-NS">{window.emeterai_fe_version}</div>
    </div>
  )
}

const Profile = ({
  menuItems = [],
  ...props
}) => {
  const [name, setName] = useState('...');

  useEffect(() => {
    if(props.authStore.isLoggedIn){
      try { setName(props.authStore.user.name.split(' ')[0]); } catch(e){}
    }
    if(props.username){
      setName(props.username)
    }
  }, [props.authStore.isLoggedIn])

  useEffect(() => {
    if(props.username){
      setName(props.username)
    }
  }, [props.username])

  const handleProfile = () => {
    if(props.handleProfile){
      props.handleProfile()
    } else {
      if(window.location.href.indexOf('localhost') == -1 || window.location.href.indexOf('demo') == -1){
        window.open("https://user.pajakku.com/main/account/user")
      } else {
        window.open("https://user.bdg.pajakku.com/main/account/user")
      }
    }
  }

  const handleLogout = () => {
    if(props.handleLogout){
      props.handleLogout()
    } else {
      props.modalStore.showConfirm({
        title: t.translate('mpk.column.logout'),
        children: t.translate('mpk.sentence.logoutMessage'),
        onSubmit: () => {
          props.authStore.logout();
        }
      })
    }
  }

  const handleApplicationInfo = () => {
    props.modalStore.showInfo({
      title: t.translate('mpk.sentence.applicationInfo'),
      children: (<AppInfo {...props}/>)
    })
  }

  menuItems = [
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-account"/>,
      children: t.translate('mpk.column.profile'),
      onClick: handleProfile
    },
    ...menuItems,
    'separator',
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-logout"/>,
      children: t.translate('mpk.column.logout'),
      onClick: handleLogout
    },
    menuItems.length > 0 ? 'separator' : null,
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-information"/>,
      children: t.translate('mpk.column.info'),
      onClick: handleApplicationInfo
    }
  ]

  return (
    <div className="mpk-profile">
      <DropdownMenu
        id="mpk-profile-menu"
        items={menuItems}
        disableDropdownIcon
      >
        <Flex
          align={Flex.properties.align.CENTER}
        >
          <span className="mpk-margin-N margin-right">{name}</span>
          <Picture/>
        </Flex>
      </DropdownMenu>
    </div>
  )
}

Profile.Picture = Picture

export default inject('modalStore', 'authStore', 'envStore')(observer(Profile));
